<template>
    <div>
        <van-action-sheet v-model="showAction" :title="title" class="modalclass">
            <div class="payClass">
                <div class="numcard">
                    <div class="txttip">
                        <div class="tipnum">{{actionInfo.price}}</div>
                        <div class="tip">锁仓({{actionInfo.coin_symbol}})</div>
                    </div>
                    <div class="txttip">
                        <div class="tipnum">{{actionInfo.profit}}</div>
                        <div class="tip">预期获得(ATT)</div>
                    </div>
                </div>
                <div class="btnContent" @click="clickPay">
                    <div class="btn">参与</div>
                </div>
                <div class="surplus">剩余次数：{{actionInfo.shenCount}}次</div>
                <div class="notice">
                    注意<br/>
                    1.{{title}}会锁仓{{actionInfo.price}}{{actionInfo.coin_symbol}}，最多{{title}}{{actionInfo.limit}}次<br/>
                    2.{{actionInfo.day}}天后锁仓{{actionInfo.coin_symbol}}会退回，并可获得ATT奖励<br/>
                    3.每次{{title}}会获得活跃值
                </div>
            </div>
        </van-action-sheet>
        <van-dialog v-model="showpwd" :title="title+'参与'" @cancel="cancel" @confirm="confirm" show-cancel-button>
            <van-field type="password" v-model="password" name="安全密码" label="安全密码" placeholder="请输入安全密码"></van-field>
        </van-dialog>
    </div>
</template>

<script>

    import {getHuanList, getWalletInfo, canHuan} from '@/request/api'
    import {Toast} from 'vant';
    import $ from 'jquery'

    export default {
        name: "CodeInvite",

        data() {
            return {
                title: "播种",
                showAction: false,
                actionInfo: {},
                listData: [],
                itemname: '',  //1播种 2浇水 3施肥 4采果
                address:'' ,
                showpwd: false,
                password: '',
                userInfo:{},
                walletList:[],
            };
        },
        activated() {

        },
        methods: {
            cancel() {
                this.showpwd = false;
                this.password = '';
            },
            confirm () {
                let _this = this;
                if(!this.address){
                    Toast('请使用币安智能链链接');
                    return
                }
                if(this.address&&this.userInfo.has_paypwd!=1){
                    Toast('请先完善资料,设置安全密码');
                    setTimeout(function () {
                        _this.$router.push({
                            path:'/home/mine'
                        })
                    })
                    return
                }
                if(this.password.length === 0){
                    Toast('请输入安全密码')
                    return;
                }
                if(!this.actionInfo.huan_package_id){
                    Toast('选择环保行为');
                    return;
                }
                if(this.actionInfo.shenCount<1){
                    Toast('该环保行为参与次数已达上限');
                    return
                }
                if(parseFloat(this.actionInfo.price)>parseFloat(this.walletList[this.actionInfo.coin_symbol])){
                    Toast(this.actionInfo.coin_symbol+'可用余额不足');
                    return
                }
                const params = {
                    address:this.address,
                    pay_code:this.password,
                    id:this.actionInfo.huan_package_id,
                }
                canHuan(params).then(res=> {
                    _this.password = '';
                    if(res.code==200){
                        Toast(this.title+'成功')
                    }
                    else if(res.code==100000){
                        Toast('环保套餐库存不足')
                    }
                    else if(res.code==100001){
                        Toast('安全密码错误')
                    }
                    else if(res.code==100002){
                        Toast('可用余额不足')
                    }
                    else{
                        Toast(this.title+'失败')
                    }
                   /* setTimeout(function () {
                        location.reload()
                    },3000)*/
                }).catch(err=> {
                    Toast(this.title+'失败')
                  /*  setTimeout(function () {
                        location.reload()
                    },3000)*/
                })
            },
            getWalletList() {
                let _this = this;
                const params = {
                    address: this.address
                }
                getWalletInfo(params).then((res)=> {
                    $.each(res.data.list,function (i,ele) {
                        _this.walletList[ele.coin_symbol] = parseFloat(ele.cloud_balance).toFixed(4);
                    })
                    console.log(_this.walletList)
                })
            },
            preview(itemInfo) {
                this.showAction = true;
                this.itemname = itemInfo;
                this.address = sessionStorage.getItem("address");
                this.userInfo = sessionStorage.getItem("user")?JSON.parse(sessionStorage.getItem("user")):{};
                if(this.address){
                    this.getWalletList();
                    this.getHuanListData()
                }

            },
            clickPay() {
                if(this.actionInfo.shenCount<1){
                    Toast('该环保行为参与次数已达上限');
                    return
                }
                if(parseFloat(this.actionInfo.price)>parseFloat(this.walletList[this.actionInfo.coin_symbol])){
                    Toast(this.actionInfo.coin_symbol+'可用余额不足');
                    return
                }
                this.showpwd = true;
                this.password = '';
            },
            getHuanListData() {
                if(!this.address){
                    Toast('请使用币安智能链链接')
                    return
                }
                const params = {
                    address: this.address
                }
                getHuanList(params).then(res => {
                    this.listData = res.data
                    let filterArr = this.listData.filter(item => {
                        if (this.itemname == 0) {
                            if (item.shenCount > 0) {
                                return item.huan_package_id;
                            }
                        } else {
                            return item.huan_package_id === this.itemname
                        }
                    })
                    console.log(filterArr)
                    if (filterArr.length > 0) {
                        this.actionInfo = filterArr[0]
                        this.title = this.actionInfo.package_zh_name
                    }
                })
            }
        },
    };
</script>

<style lang="scss" scoped>
    .modalclass {
        background: #202823;
        border-radius: 12px 12px 0px 0px;
        color: #f5fff9;
        .inviteclass {
            padding: 16px 16px 100px;
            .inputcontent {
                display: flex;
                justify-content: center;
                .inputClass {
                    width: 600px;
                    height: 96px;
                    background: #29332d;
                    border-radius: 16px;
                    ::v-deep .van-field__control {
                        color: white;
                    }
                }
            }
        }
        .btnContent {
            display: flex;
            justify-content: center;
            margin-top: 80px;
            .btn {
                width: 320px;
                height: 88px;
                background: #27cc7f;
                border-radius: 16px;
                font-weight: 600;
                font-size: 36px;
                line-height: 88px;
                text-align: center;
                color: #202823;
            }
        }
        .payClass {
            padding: 16px 16px 100px;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            .numcard {
                display: flex;
                justify-content: space-around;
                align-items: center;
                width: 686px;
                height: 168px;
                background: #29332d;
                border: 1px solid rgba(39, 204, 127, 0.2);
                border-radius: 16px;
                .txttip {
                    text-align: center;
                    .tipnum {
                        font-family: "DIN";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 44px;
                        color: #d6ffe3;
                    }
                    .tip {
                        font-family: "PingFang SC";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 24px;
                        color: #b6d9c3;
                    }
                }
            }
            .surplus {
                margin-top: 10px;
                width: 100%;
                font-family: "PingFang SC";
                font-style: normal;
                font-weight: 400;
                font-size: 28px;
                text-align: center;
                color: #8ba695;
            }
            .notice {
                width: 100%;
                padding: 0 36px;
                font-family: "PingFang SC";
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                line-height: 50px;
                color: #b6d9c3;
            }
        }
    }
</style>