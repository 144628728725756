<template>
  <div>
    <van-action-sheet v-model="showSheet" :close-on-click-overlay="false" title="完善资料" class="modalclass">
        <div class="infoContent">
            <van-form>
                <van-field class="inputClass" v-model="username" name="昵称" label="昵称" placeholder="请输入昵称" :rules="[{ required: true, message: '请输入昵称' }]"
                ></van-field>
                <van-field class="inputClass" readonly
                clickable :value="phone" name="手机验证" label="手机验证" placeholder="请输入手机号" :rules="[{ required: true, message: '请输入手机号' }]"
                @touchstart.native.stop="showKeyboard = true" >
                    <template #button>
                       <div @click.stop="sendVerfity" class="sendbtn">{{ message }}</div>
                    </template>
                </van-field>
                <van-number-keyboard  v-model="phone" v-if="showPhone" :show="showKeyboard" :maxlength="11" @blur="showKeyboard = false"/>
                 <van-field class="inputClass" v-model="verfityCode" name="验证码" label="验证码" placeholder="请输入验证码" :rules="[{ required: true, message: '请输入验证码' }]"
                ></van-field>
                <van-field type="password" class="inputClass" v-model="password" name="安全密码" label="安全密码" placeholder="请输入安全密码" :rules="[{ required: true, message: '请输入安全密码' }]"
                ></van-field>
            </van-form>
        </div>

        <div class="paytitle">支付方式</div>
        <div class="payWay" @click="goPayway">
            <div class="waycard">
                <img class="payicon" :src="require('@/assets/images/mine/zhifubao.png')" />
                <div>{{zfpay === true ? '已设置' : '未设置'}}</div>
            </div>
            <div class="waycard">
                <img class="payicon" :src="require('@/assets/images/mine/usdt.png')" />
                <div>{{ wechatpay=== true ? '已设置' : '未设置'}} </div>
            </div>
            <div class="waycard">
                <img class="payicon" :src="require('@/assets/images/mine/card.png')" />
                <div>{{cardpay === true ? '已设置' : '未设置'}}</div>
            </div>
        </div>
  <!--      <div class="realname">
            <div class="left">
                <div class="txt">
                    实名认证
                </div>
                <div class="status" v-if="userInfo.is_auth==1">
                    已实名
                </div>
                <div class="status" v-if="userInfo.is_auth==0">
                    未实名
                </div>
                <div class="status" v-if="userInfo.is_auth==2">
                    认证中
                </div>
            </div>  
            <div class="right" @click="goToAuth" v-if="userInfo.is_auth==0">
                去认证 >
            </div>      
        </div>-->
        <div class="submit">
            <div class="submitbtn" @click="clickSubmit">
                提交
            </div>
        </div>
    </van-action-sheet>
  </div>
</template>

<script>
import metamaskConfig from '../../../components/connection.js'
import { ethers } from '../../../components/ethers-5.1.esm.min.js'
import {getCode, userComplete, getPaylist,getUserInfo} from '@/request/api'
import { Toast } from 'vant'
export default {
    data () {
        return {
            showPhone:true,
            showSheet:false,
            username:'',
            phone: '',
            showKeyboard:false,
            verfityCode: '',
            password: '',
            paylist: [],
            wechatpay:false,
            zfpay:false,
            cardpay:false,
            address:'',
            userInfo:{},
            message:'发送',
            time:0,
            disabled:false,
        } 
    },
    activated() {

    },
    methods: {
        getUserInfo(){
            getUserInfo({address:this.address}).then((res)=> {
                sessionStorage.removeItem("user");
                sessionStorage.removeItem("level_id");
                sessionStorage.setItem("user", JSON.stringify(res.data));
                sessionStorage.setItem("level_id", res.data.level_id);
                console.log(sessionStorage.getItem("user"))
                this.userInfo = res.data;
                this.phone = this.userInfo.mobile;
                this.username = this.userInfo.name;
                if(this.userInfo.mobile){
                    this.showPhone = false;
                }
            })
        },
        preview() {
            this.showSheet = true;
            this.address = sessionStorage.getItem("address");
            if(this.address &&sessionStorage.getItem(this.address)){
                this.getUserInfo();
                this.getPaylistData()
            }
        },
        goPayway () {
            this.showSheet = false
            this.$router.push({
				path:'/home/mine/payway'
			})
        },
        sendVerfity () {
            if(this.phone.length === 0){
                Toast('请输入手机号');
                return
            }
            this.getVerfityCode()
        },
        getVerfityCode () {
            if(this.disabled){
                return false;
            }
            this.disabled = true;
            const params = {
                address:this.address,
                mobile:this.phone
            }
            getCode(params).then(res => {
                if(res.code==200){
                    Toast('发送成功');
                    this.message = 60;
                    this.timer()
                }
                else{
                    Toast('今日发送已达上限');
                }
            })
        },
        timer(){
            let _this = this;
            if (this.message > 0) {
                setTimeout(function() {
                        _this.message--;
                        _this.timer()
                    },
                    1000)
            } else {
                this.message = '发送';
                this.disabled = false
            }
        },
        async clickSubmit () {
            if(!this.address||!sessionStorage.getItem(this.address)){
                Toast('请使用币安智能链链接')
                return false;
            }
            const params = {
                address:this.address,
                name: this.username,
                mobile:this.phone,
                verification_code:this.verfityCode,
                pay_code:this.password
            }
            userComplete(params).then(res=> {
                console.log(res)
                if(res.code==200) {
                    Toast('提交成功')
                    this.username = ''
                    this.mobile = ''
                    this.verfityCode = ''
                    this.password = ''
                    this.showSheet = false
                }else if(res.code==100001){
                    Toast('该手机号已经绑定,请填写其它号码');
                }else{
                    Toast(res.msg)
                }

            })
           /* try {
                let message = "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef";
                let messageBytes = ethers.utils.arrayify(message);
                let signature = await metamaskConfig.signer.signMessage(messageBytes)
                if(signature){

                }

            } catch (e) {
                Toast('操作失败');
                setTimeout(function () {
                    location.reload();
                },2500)
            }*/

        },
        goToAuth() {
            this.showSheet = false
            this.$router.push({
				path:'/home/mine/realnameAuth'
			})
        },
        getPaylistData (){
            if(!this.address){
                Toast('请使用币安智能链链接')
                return
            }
            const params = {
                address:this.address,
            }
            getPaylist(params).then(res=>{
                this.paylist = res.data
                this.paylist.filter(item=> {
                    if(item.pay_type === 1&& item.disable==0){
                        this.wechatpay = true
                    }else if(item.pay_type === 2 && item.disable==0) {
                        this.zfpay = true
                    }else if(item.pay_type === 3 && item.disable==0){
                        this.cardpay = true
                    }
                })
            })
        }
    }
}

</script>

<style scoped lang="scss">
::v-deep .van-action-sheet__header{
    color: white;
}
.modalclass {
    background: #202823;
    border-radius: 12px 12px 0px 0px;
    padding-bottom: 60px;
    .infoContent {
        padding: 16px 16px;
        .inputClass {
            background: #29332D;
            ::v-deep .van-field__control{
                color: white;
            }
            .sendbtn {
                 color: rgba(39,204,127,1);
                font-size: 28px;
                font-weight: 400;
                font-family: "PingFang SC";
            }
        } 
    }
    .paytitle {
        padding: 32px 0px 24px 32px;
        color: rgba(214,255,227,1);
        font-size: 32px;
        font-weight: 400;
        font-family: "PingFang SC";
    }
    .payWay {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        .waycard {
            width: 204px;
            height: 80px;
            border-radius: 16px;
            opacity: 1;
            background: rgba(41,51,45,1);
            font-size: 28px;
            font-weight: 400;
            font-family: "PingFang SC";
            color: rgba(214,255,227,1);
            display: flex;
            align-items: center;
            justify-content: space-around;
            .payicon {
                width: 64px;
                height: 64px;
            }
        }
    }
    .realname {
        margin-top: 32px;
        padding: 0px 32px;
        display: flex;
        justify-content: space-between;
        .txt {
            display: inline-block;
            color: rgba(214,255,227,1);
            font-size: 32px;
            font-weight: 400;
            margin-right: 30px;
        }
        .status {
            display: inline-block;
            width: 128px;
            height: 40px;
            border-radius: 8px;
            opacity: 1;
            background: rgba(39,199,204,1);
            text-align: center;
            line-height: 40px;
        }
        .right {
            color: rgba(39,204,127,1);
            font-size: 28px;
            font-weight: 400;
            font-family: "PingFang SC";
            text-align: center;
        }
    }
    .submit {
        margin-top: 50px;
        width: 100%;
        display: flex;
        justify-content: center;
        .submitbtn {
            width: 320px;
            height: 88px;
            border-radius: 16px;
            opacity: 1;
            background: rgba(39,204,127,1);
            color: rgba(32,40,35,1);
            font-size: 36px;
            font-weight: 700;
            font-family: "PingFang SC";
            text-align: center;
            line-height: 88px;
        }
    }
}
</style>