<template>
  <div id="app">
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
    <template v-if="loading">
      <div class="loadWindow"></div>
      <div class="loadBox">
        <div class="loadTitle">加载中...</div>
        <img src="./assets/images/load.gif" style="width: 100%" />
      </div>
    </template>
    <template v-if="is_freeze">
      <div class="loadWindow"></div>
      <div class="loadBox" style="padding: 8% 0;" v-if="userInfo.freeze_time">
        <div class="loadTitle">冻结到期时间:</div>
        <div style="font-size: 26px;">
          {{ userInfo.freeze_time }}
        </div>
      </div>
      <div class="loadBox" style="padding: 8% 5%;" v-else>
        <div class="loadTitle">温馨提示：该地址已触发风控系统规则</div>
      </div>
    </template>
  </div>
</template>

<script>
import metamaskConfig from "./components/connection.js";
import { ethers } from "./components/ethers-5.1.esm.min.js";
import { register, getUserInfo } from "@/request/api";
import { Toast } from "vant";

let defaultAccount, networkVersion;
export default {
  name: "App",
  components: {},
  data() {
    return {
      address: "",
      loading: false,
      showMessage: false,
        is_freeze:false,
        userInfo:{},
    };
  },
  created() {
    this.checkLogin();
  },
  async mounted() {},
  methods: {
    async checkAddress() {
      // console.log(this.loading);
      // console.log(sessionStorage.getItem(this.address));
      if (!sessionStorage.getItem(this.address)) {
        if (!this.showMessage) {
          this.showMessage = true;
          if (this.loading) {
            location.reload();
            return false;
          }
          this.loading = true;
          try {
            let message =
              "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef";
            let messageBytes = ethers.utils.arrayify(message);
            let signature = await metamaskConfig.signer.signMessage(
                "aims to awaken human beings to enhance their awareness of environmental protection and promote the concept of green environmental protection"
            );
            if (signature) {
              this.loading = false;
              this.showMessage = false;
              sessionStorage.setItem(this.address, true);
            }
          } catch (e) {
            this.showMessage = false;
          }
        }
      }
    },
    async checkLogin() {
      let _this = this;
      try {
        if (metamaskConfig.isMetamaskConnected) {
          ethereum.autoRefreshOnNetworkChange = false;
          networkVersion = await metamaskConfig.getNetworkId();
          if (networkVersion == 1) {
            //以太坊主网
            console.log("ETH");
            Toast("请使用币安智能链链接");
            return false;
          } else if (networkVersion == 42) {
            //以太坊测试网  K
            console.log("ETH");
            Toast("请使用币安智能链链接");
            return false;
          } else if (networkVersion == 128) {
            //HECO主网
            console.log("HT");
            Toast("请使用币安智能链链接");
            return false;
          } else if (networkVersion == 256) {
            //HECO测试网
            console.log("HT");
            Toast("请使用币安智能链链接");
            return false;
          } else if (networkVersion == 56) {
            //币安主网
            console.log("BNB");
          } else {
            console.log("其他");
            Toast("请使用币安智能链链接");
            return false;
          }
          defaultAccount = await metamaskConfig.connectToAccount();
          // console.log("address connected:", defaultAccount);
          if (defaultAccount[0]) {
            this.address = defaultAccount[0];
            sessionStorage.removeItem("address");
            sessionStorage.removeItem("user");
            sessionStorage.removeItem("level_id");
            sessionStorage.setItem("address", this.address);
            setInterval(function () {
              _this.checkAddress();
            }, 2000);
            this.regUser();
          }
        } else {
          Toast("请使用币安智能链链接");
          // console.log("Connect to available ethereum network!");
        }
      } catch (e) {
        console.log("1111");
      }
    },
    regUser() {
      let _this = this;
      register({ address: this.address }).then((res) => {
        _this.getUserInfo();
      });
    },
    getUserInfo() {
      getUserInfo({ address: this.address }).then((res) => {
          this.userInfo = res.data;
          if(this.userInfo.freeze==1){
              this.is_freeze = true;
          }
        sessionStorage.setItem("user", JSON.stringify(res.data));
        sessionStorage.setItem("level_id", res.data.level_id);
        // console.log(sessionStorage.getItem("user"));
      });
    },
  },
};
</script>

<style>
::-webkit-scrollbar {
  display: none;
}
/*加载中开始*/
.loadWindow {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  background: #000;
  opacity: 0.7;
  z-index: 3000;
}

.loadBox {
  position: fixed;
  width: 50%;
  left: 25%;
  text-align: center;
  top: 25%;
  z-index: 3001;
  background: #fff;
  padding: 10px;
  border-radius: 5px;
}
.loadTitle {
  color: #17155e;
  font-size: 16px;
  font-weight: bold;
  margin: 10px 0;
}
/*加载中结束*/
</style>
