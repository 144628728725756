import Login from "../pages/login/login.vue";
import Home from "../pages/home/home.vue";
import Index from "../pages/index/index.vue";
import Scene from "../pages/scene/scene.vue";
import Mine from "../pages/mine/mine.vue";
import Task from "../pages/task/task.vue";
import Page404 from "../pages/page404/index.vue";
import Child from "../pages/child/child.vue";

const routes = [
    {
        path: "/login",
        component: Login,
    },
    {
        name: "home",
        path: "/home",
        component: Home,
        children: [
            {
                name: "index",
                path: "index",
                component: Index,
            },
            {
                name: "task",
                path: "task",
                component: Task,
            },
            {
                name: "scene",
                path: "scene",
                component: Scene,
            },
            {
                name: "mine",
                path: "mine",
                component: Mine,
            },
            {
                path: "/",
                redirect: "index",
            },
        ],
    },
    {
        name: "environmentrecord",
        path: "/home/index/environmentrecord",
        component: () => import("@/pages/index/environmentrecord.vue"),
    },
    {
        name: "bindinvite",
        path: "/home/index/bindinvite",
        component: () => import("@/pages/index/bindinvite.vue"),
    },
    {
        name: "announce",
        path: "/home/index/announce",
        component: () => import("@/pages/index/announce.vue"),
    },
    {
        name: "transfor",
        path: "/home/index/transfor",
        component: () => import("@/pages/index/transfor.vue"),
    },
    {
        name: "anndetail",
        path: "/home/index/announce/detail",
        component: () => import("@/pages/index/anndetail.vue"),
    },
    {
        name: "transactions",
        path: "/home/mine/transactions",
        component: () => import("@/pages/mine/transactions.vue"),
    },
    {
        name: "topup",
        path: "/home/mine/transactions/topup",
        component: () => import("@/pages/mine/topup.vue"),
    },
    {
        name: "withdrawal",
        path: "/home/mine/transactions/withdrawal",
        component: () => import("@/pages/mine/withdrawal.vue"),
    },
    {
        name: "transactionsexchange",
        path: "/home/mine/transactions/exchange",
        component: () => import("@/pages/mine/exchange.vue"),
    },
    {
        name: "recordList",
        path: "/home/mine/transactions/recordList",
        component: () => import("@/pages/mine/recordList.vue"),
    },
    {
        name: "team",
        path: "/home/mine/team",
        component: () => import("@/pages/mine/team.vue"),
    },
    {
        name: "operation",
        path: "/home/mine/operation",
        component: () => import("@/pages/mine/operation.vue"),
    },
    {
        name: "operatedetail",
        path: "/home/mine/operation/operatedetail",
        component: () => import("@/pages/mine/operatedetail.vue"),
    },
    {
        name: "contribution",
        path: "/home/mine/contribution",
        component: () => import("@/pages/mine/contribution.vue"),
    },
    {
        name: "devoteLog",
        path: "/home/mine/devoteLog",
        component: () => import("@/pages/mine/devoteLog.vue"),
    },
    {
        name: "poster",
        path: "/home/mine/poster",
        component: () => import("@/pages/mine/poster.vue"),
    },
    {
        name: "fanLog",
        path: "/home/mine/fanLog",
        component: () => import("@/pages/mine/fanLog.vue"),
    },
    {
        name: "daiLog",
        path: "/home/mine/daiLog",
        component: () => import("@/pages/mine/daiLog.vue"),
    },
    {
        name: "onlineserve",
        path: "/home/mine/onlineserve",
        component: () => import("@/pages/mine/onlineserve.vue"),
    },
    {
        name: "realnameAuth",
        path: "/home/mine/realnameAuth",
        component: () => import("@/pages/mine/realnameAuth.vue"),
    },
    {
        name: "tradeCoin",
        path: "/home/mine/tradeCoin",
        component: () => import("@/pages/mine/trade.vue"),
    },
    {
        name: "devoterank",
        path: "/home/mine/devoterank",
        component: () => import("@/pages/mine/devoterank.vue"),
    },
    {
        name: "payway",
        path: "/home/mine/payway",
        component: () => import("@/pages/mine/payway.vue"),
    },
    {
        name: "addpayway",
        path: "/home/mine/addpayway",
        component: () => import("@/pages/mine/addpayway.vue"),
    },
    {
        name: "indexexchange",
        path: "/home/index/exchange",
        component: () => import("@/pages/index/exchange.vue"),
    },
    {
        name: "question",
        path: "/home/scene/question",
        component: () => import("@/pages/scene/question.vue"),
    },
    {
        name: "vote",
        path: "/home/scene/vote",
        component: () => import("@/pages/scene/vote.vue"),
    },
    {
        name: "buynow",
        path: "/task/buynow",
        component: () => import("@/pages/task/buynow.vue"),
    },
    {
        name: "sellnow",
        path: "/task/sellnow",
        component: () => import("@/pages/task/sellnow.vue"),
    },
    {
        name: "buying",
        path: "/task/buying",
        component: () => import("@/pages/task/buying.vue"),
    },
    {
        name: "sale",
        path: "/task/sale",
        component: () => import("@/pages/task/sale.vue"),
    },
    {
        name: "order",
        path: "/task/order",
        component: () => import("@/pages/task/order.vue"),
    },
    {
        name: "hanglog",
        path: "/task/hanglog",
        component: () => import("@/pages/task/hanglog.vue"),
    },
    {
        name: "mallhome",
        path: "/mall/mallhome",
        component: () => import("@/pages/mall/mallhome.vue"),
    },
    {
        name: "goodsdetail",
        path: "/mall/goodsdetail",
        component: () => import("@/pages/mall/goodsdetail.vue"),
    },
    {
        name: "myorderlist",
        path: "/mall/myorderlist",
        component: () => import("@/pages/mall/myorderlist.vue"),
    },
    {
        name: "paypage",
        path: "/mall/paypage",
        component: () => import("@/pages/mall/paypage.vue"),
    },
    {
        name: "addressedit",
        path: "/mall/addressedit",
        component: () => import("@/pages/mall/addressedit.vue"),
    },
    {
        name: "commentlist",
        path: "/mall/commentlist",
        component: () => import("@/pages/mall/commentlist.vue"),
    },
    {
        name: "goodslist",
        path: "/mall/goodslist",
        component: () => import("@/pages/mall/goodslist.vue"),
    },
    {
        name: "applyshop",
        path: "/mall/applyshop",
        component: () => import("@/pages/mall/applyshop.vue"),
    },
    {
        name: "applyprompt",
        path: "/mall/applyprompt",
        component: () => import("@/pages/mall/applyprompt.vue"),
    },
    {
        name: "malllogin",
        path: "/mall/malllogin",
        component: () => import("@/pages/mall/malllogin.vue"),
    },
    {
        name: "forgetpwd",
        path: "/mall/forgetpwd",
        component: () => import("@/pages/mall/forgetpwd.vue"),
    },
    {
        name: "completedata",
        path: "/mall/completedata",
        component: () => import("@/pages/mall/completedata.vue"),
    },
    {
        name: "shopmanage",
        path: "/mall/shopmanage",
        component: () => import("@/pages/mall/shopmanage.vue"),
    },
    {
        name: "warehouse",
        path: "/mall/warehouse",
        component: () => import("@/pages/mall/warehouse.vue"),
    },
    {
        name: "earnings",
        path: "/mall/earnings",
        component: () => import("@/pages/mall/earnings.vue"),
    },
    {
        name: "publishgood",
        path: "/mall/publishgood",
        component: () => import("@/pages/mall/publishgood.vue"),
    },
    {
        name: "mallgood",
        path: "/mall/mallgood",
        component: () => import("@/pages/mall/mallgood.vue"),
    },
    {
        name: "mallorder",
        path: "/mall/mallorder",
        component: () => import("@/pages/mall/mallorder.vue"),
    },
    {
        path: "/child",
        component: Child,
    },
    {
        path: "/",
        component: Home,
    },
    {
        path: "*",
        component: Page404, //404页面
    },
];

export default routes;
