<template>
  <div>
    <Header :showback="false" :customstyle="{ background: '#0D1912' }">
      <template slot="title">交易</template>
    </Header>
    <div class="task-section">
      <div class="menubtn">
        <div class="btn">
          <div
                  :class="type === 'USDT' ? 'getbtn' : 'consumptionbtn'"
                  @click="changeMenu('USDT')"
          >
            USDT
          </div>
          <div
            :class="type === 'ATT' ? 'getbtn' : 'consumptionbtn'"
            @click="changeMenu('ATT')"
          >
            ATT
          </div>

         <!--
          <div
            :class="type === 'ARR' ? 'getbtn' : 'consumptionbtn'"
            @click="changeMenu('ARR')"
          >
            ARR
          </div>-->
        </div>
      </div>
      <div class="card">
        <div class="top"  v-if="type != 'USDT'">
          <div class="item">
            <div class="num">
              {{ parseFloat(tradeInfo.trade_yesterday_price).toFixed(3) || 0 }}
            </div>
            <div class="txt">今日参考(USD)</div>
          </div>
          <div class="item">
            <div class="num">
              {{ parseFloat(tradeInfo.trade_yesterday_price).toFixed(3) || 0 }}
            </div>
            <div class="txt">昨天平均(USD)</div>
          </div>
          <div class="item"   v-if="userInfo.is_usdt_shop==1">
            <div style="text-align: center;">
              <img

                      @click="clickstop"
                      class="stopimg marginR16px"
                      :src="require('@/assets/images/usdtnew.png')"
              />
            </div>
            <div class="txt">我的商家</div>
          </div>
          <div class="item"   v-if="userInfo.is_usdt_shop==0">
            <div style="text-align: center;">
              <img
                      @click="clickopen"
                      class="stopimg marginR16px"
                      :src="require('@/assets/images/usdtnew.png')"
              />
            </div>
            <div class="txt">申请商家</div>
          </div>
          <!--<div class="item">
            <div class="num">{{ parseFloat(tradeInfo.trade_rate*100).toFixed(2)+'%'|| 0 }}</div>
            <div class="txt">涨幅</div>
          </div>-->
        </div>
        <div class="end" v-if="type != 'USDT'">
          <div class="item">
            <div class="num">{{ tradeInfo.att_today_destory }}</div>
            <div class="txt">昨日销毁(ATT)</div>
          </div>
          <div class="item">
            <div class="num">{{ tradeInfo.att_total_destory }}</div>
            <div class="txt">总计销毁(ATT)</div>
          </div>
          <div class="item">
            <div class="num">{{ tradeInfo.arr_today_destory }}</div>
            <div class="txt">昨日销毁(ARR)</div>
          </div>
        </div>
        <div class="top"  v-if="type == 'USDT'">
          <div class="item">
            <div class="num">
              {{ parseFloat(tradeInfo.trade_yesterday_price).toFixed(3) || 0 }}
            </div>
            <div class="txt">今日参考(USD)</div>
          </div>
          <div class="item">
            <div class="num">{{ tradeInfo.buyTotalMoney }}</div>
            <div class="txt">求购总量</div>
          </div>
          <div class="item"  style="width: 33%;text-align: right;"  v-if="userInfo.is_usdt_shop==1">
            <div style="margin-right: 8px;">
              <img

                      @click="clickstop"
                      class="stopimg marginR16px"
                      :src="require('@/assets/images/usdtnew.png')"
              />
            </div>
            <div class="txt">我的商家</div>
          </div>
          <div class="item"  style="width: 33%;text-align: right;"  v-if="userInfo.is_usdt_shop==0">
            <div style="margin-right: 8px;">
              <img
                      @click="clickopen"
                      class="stopimg marginR16px"
                      :src="require('@/assets/images/usdtnew.png')"
              />
            </div>
            <div class="txt">申请商家</div>
          </div>
        </div>
        <div class="end">
          <div class="item">
            <div class="num">{{ tradeInfo.totalMoney }}</div>
            <div class="txt">今日流通</div>
          </div>
            <div class="item btn"  v-if="type == 'USDT'">
                <div class="btnsell" @click="clickSale()">卖出</div>
            </div>
          <div class="item"  v-if="type != 'USDT'">
            <div class="num">{{ tradeInfo.buyTotalMoney }}</div>
            <div class="txt">求购总量</div>
          </div>
          <div class="btn">

            <div class="btnbuy" @click="clickBuying()">买入</div>
          </div>
        </div>
        <div class="recordlog"><div @click="clickhangLog">挂单记录 ></div></div>
      </div>
      <div class="buyandsell">
        <van-tabs
          class="tab"
          title-active-color="#27CC7F"
          title-inactive-color="#8BA695"
          background="#0C1913"
          color="#27CC7F"
          @change="changeTab"
          v-model="activeTab"
        >
          <!--v-model="activeTab"-->
          <!--@change="changeTab"-->
          <van-tab title="出售"></van-tab>
          <van-tab title="买入" v-if="type == 'USDT'"></van-tab>
        </van-tabs>
        <div class="order" @click="clickOrder()">订单记录 ></div>
      </div>
      <div class="orderBy">
        <div class="ocontent" @click="clickPriceOrder">
          <div class="ordertxt">价格</div>
          <img
            v-if="priceorder === 'price desc'"
            class="ordericon"
            :src="require('@/assets/images/task/order_bottom.png')"
          />
          <img
            v-else
            class="ordericon"
            :src="require('@/assets/images/task/order_top.png')"
          />
        </div>
      </div>
      <div class="list">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="暂无数据"
            @load="onLoad"
          >
            <div class="cell" v-for="item in dataList" :key="item.id">
              <div class="title">{{ item.entrust_no }}</div>
              <div class="middle">
                <div class="price" v-if="item.stock_code=='USDT'">
                  <div class="num">{{ (parseFloat(item.price)*parseFloat(tradeInfo.cny_to_us)).toFixed(2) }}</div>
                  <div class="txt">单价</div>
                </div>
                <div class="price" v-else>
                  <div class="num">{{ parseFloat(item.price).toFixed(3) }}</div>
                  <div class="txt">单价</div>
                </div>
                <div class="price offset">
                  <div class="txt">
                    <template v-if="activeTab == 0"> 求购量：</template>
                    <template v-else>求购量：</template>
                    {{ parseFloat(item.deals_remain_num).toFixed(6) }}{{item.stock_code}}
                  </div>
                  <!--<div class="txt">-->
                    <!--限额CNY：{{ parseFloat(item.min_price).toFixed(2) }}-{{-->
                      <!--parseFloat(item.max_price).toFixed(2)-->
                    <!--}}-->
                  <!--</div>-->
                  <div class="txt">
                    总价值：{{ (parseFloat(item.deals_remain_num)*parseFloat(item.price)).toFixed(2) }} USD/{{ (parseFloat(item.deals_remain_num)*parseFloat(item.price)*parseFloat(tradeInfo.cny_to_us)).toFixed(2) }} CNY
                  </div>
                </div>
              </div>
              <div class="end">
                <div class="way">
                  <div class="method">
                    支付方式：
                    <div
                      class="payway"
                      v-for="pay in item.pay_type.split(',')"
                      :key="pay"
                    >
                      <img
                        v-if="pay === '1'"
                        class="payicon"
                        :src="require('@/assets/images/mine/usdt.png')"
                      />
                      <img
                        v-if="pay === '2'"
                        class="payicon"
                        :src="require('@/assets/images/mine/zhifubao.png')"
                      />
                      <img
                        v-if="pay === '3'"
                        class="payicon"
                        :src="require('@/assets/images/mine/card.png')"
                      />
                    </div>
                  </div>
                </div>
                <div class="buy" @click="clickBuy(item)">
                  <template v-if="activeTab == 0">出售</template>
                  <template v-if="activeTab == 1">立即购买</template>
                </div>
              </div>
            </div>
          </van-list>
        </van-pull-refresh>
      </div>
    </div>
    <van-dialog
            v-model="showpwd"
            title="请输入安全码"
            @cancel="showpwd = false"
            @confirm="confirmTrade"
            show-cancel-button
    >
      <van-field
              type="password"
              v-model="password"
              name="安全密码"
              label="安全密码"
              placeholder="请输入安全密码"
      ></van-field>
    </van-dialog>
  </div>
</template>

<script>
import Header from "../../components/header.vue";
import { tradeStatistics, buyList, sellList,getTradeConfig,exitTradeUsdt,applyTradeUsdt } from "@/request/api";
import { Toast,Dialog } from "vant";
export default {
  name: "",
  components: {
    Header,
  },
  data() {
    return {
      priceorder: "price desc",
      type: "USDT",
      activeTab:0,
      tradeInfo: {
        cny_to_us: "",
        et_price: "",
        trade_period: "",
        trade_att_yesterday_price: "",
        trade_att_rate: "",
        price: "",
        trade_yesterday_price: "",
        trade_rate: "",
        totalMoney: 0,
          att_today_destory:0,
          att_total_destory:0,
          ept_today_destory:0,
          arr_today_destory:0,
      },
      currentPage: 1,
      dataList: [],
      refreshing: false,
      loading: false,
      finished: false,
        userInfo: {},
        tradeCofig:{},
        showpwd:false,
        password:'',
        applyType:0, //1申请商家 2退出商家
        address:'',
    };
  },
  computed: {},
  activated() {
    this.activeTab = 0;
    this.gettradeStatistics();
      this.gettradeConfig();
      this.userInfo = sessionStorage.getItem("user")
          ? JSON.parse(sessionStorage.getItem("user"))
          : {};
      this.address = sessionStorage.getItem("address");
  },
  methods: {
      confirmTrade(){
          let _this = this;
          if (this.password.length === 0) {
              Toast("请输入安全码");
              return;
          }
          const params = {
              pay_code: this.password,
              address: this.address,
          };
          if(_this.applyType==1){  //申请商家
              applyTradeUsdt(params).then((res) => {
                  if (res.code == 200) {
                      Toast('申请成功');
                      setTimeout(function () {
                          location.reload();
                      },2500)
                  } else {
                      Toast(res.msg);
                  }
              });
          }else{  //退出商家
              exitTradeUsdt(params).then((res) => {
                  if (res.code == 200) {
                      Toast('退出成功');
                      setTimeout(function () {
                          location.reload();
                      },2500)
                  } else {
                      Toast(res.msg);
                  }
              });
          }

      },
      gettradeConfig(){
          getTradeConfig().then((res) => {
              this.tradeCofig = res.data;
          });
      },
      clickopen(){
          let _this = this;
          // Toast('等待开放');
          // return false;
          Dialog.confirm({
              title: "申请商家",
              message: "您确定申请商家？",
              confirmButtonText: "确认",
              confirmButtonColor: "#FF7220",
          })
              .then(() => {
                  _this.showpwd = true;
                  _this.applyType = 1;
                  // on confirm
              })
              .catch(() => {
                  // on cancel
              });
      },
      clickstop() {
          let _this = this;
          Dialog.confirm({
              title: "解除商家",
              message: "您确定解除商家？",
              confirmButtonText: "确认",
              confirmButtonColor: "#FF7220",
          })
              .then(() => {
                  _this.showpwd = true;
                  _this.applyType = 2;
                  // on confirm
              })
              .catch(() => {
                  // on cancel
              });
      },
    clickPriceOrder() {
      if (this.priceorder == "price desc") {
        this.priceorder = "price asc";
      } else {
        this.priceorder = "price desc";
      }
      this.onRefresh();
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.refreshing = true;
      this.currentPage = 1;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
    onLoad() {
      if (this.activeTab == 1) {
          this.priceorder = "price asc";
        this.getsellList();
      } else {
          this.priceorder = "price desc";
        this.getbuyList();
      }
        this.currentPage++;
    },
    getsellList() {
      const params = {
        coin_symbol: this.type,
        limit: 20,
        page: this.currentPage,
        order: this.priceorder,
      };
      sellList(params).then((res) => {
        if (this.refreshing) {
          this.refreshing = false;
          this.dataList = [];
        }
        this.dataList = [...this.dataList, ...res.data.list];
        this.loading = false;
        if (res.data.list.length < 20) {
          this.finished = true;
        }
      });
    },
    getbuyList() {
      const params = {
        coin_symbol: this.type,
        limit: 20,
        page: this.currentPage,
      order: this.priceorder,
      };
      buyList(params).then((res) => {
        if (this.refreshing) {
          this.refreshing = false;
          this.dataList = [];
        }
        this.dataList = [...this.dataList, ...res.data.list];
        this.loading = false;
        if (res.data.list.length < 20) {
          this.finished = true;
        }
      });
    },
    gettradeStatistics() {
      const params = {
        coin_symbol: this.type,
        // address: this.$store.state.user.userInfo.address,
      };
      tradeStatistics(params).then((res) => {
        this.tradeInfo = res.data;
      });
    },
    clickSale() {
      this.$router.push({
        path: "/task/sale",
        query: {
          coin: this.type,
        },
      });
    },
    clickBuying() {
      this.$router.push({
        path: "/task/buying",
        query: {
          coin: this.type,
        },
      });
    },
    clickOrder() {
      this.$router.push({
        path: "/task/order",
        query: {
          coin: this.type,
        },
      });
    },
    clickhangLog() {
      this.$router.push({
        path: "/task/hanglog",
        query: {
          coin: this.type,
        },
      });
    },
    changeTab() {
      this.onRefresh();
    },
    changeMenu(index) {
      this.type = index;
      if(index=='ATT'){
          this.activeTab = 0;
      }
      this.gettradeStatistics();
      this.onRefresh();
    },
    clickBuy(item) {
      if (this.activeTab === 1) {
        this.$router.push({
          path: "/task/buynow",
          query: {
            id: item.id,
            type: 1,
          },
        });
      } else {
        this.$router.push({
          path: "/task/sellnow",
          query: {
            id: item.id,
            type: 2,
          },
        });
      }
    },
  },
};
</script>
<style scoped lang="scss">
.offset {
  margin-left: 90px;
}
.task-section {
  padding: 10px 32px;
  background: #0d1912;
  min-height: 100vh;
  .menubtn {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    justify-content: flex-start;
    .btn {
      display: flex;
      /*width: 488px;*/
      height: 72px;
      background: rgba(32, 40, 35, 1);
      border-radius: 24px;
      .getbtn {
        width: 160px;
        height: 72px;
        border-radius: 24px;
        opacity: 1;
        background: rgba(39, 204, 127, 1);
        color: rgba(13, 25, 18, 1);
        font-size: 32px;
        font-weight: 700;
        font-family: "PingFang SC";
        text-align: center;
        line-height: 72px;
      }
      .consumptionbtn {
        width: 160px;
        height: 72px;
        border-radius: 24px;
        opacity: 1;
        color: rgba(182, 217, 195, 1);
        font-size: 32px;
        font-weight: 700;
        font-family: "PingFang SC";
        text-align: center;
        line-height: 72px;
      }
    }
  }
  .card {
    margin-top: 36px;
    width: 686px;
    min-height: 288px;
    border-radius: 24px;
    opacity: 1;
    background: linear-gradient(
      135.4deg,
      rgba(76, 202, 129, 1) 0%,
      rgba(70, 183, 121, 1) 100%
    );
    padding: 24px;
    .top {
      display: flex;
      justify-content: space-between;
      .item {
        .num {
          color: rgba(214, 255, 227, 1);
          font-size: 44px;
          font-weight: 500;
          font-family: "DIN";
        }
        .txt {
          color: rgba(214, 255, 227, 1);
          font-size: 24px;
          font-weight: 400;
          font-family: "PingFang SC";
        }
        .stopimg {
          width: 64px;
          height: 64px;
        }
      }
    }
    .end {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      .item {
        .num {
          color: rgba(214, 255, 227, 1);
          font-size: 44px;
          font-weight: 500;
          font-family: "DIN";
        }
        .txt {
          color: rgba(214, 255, 227, 1);
          font-size: 24px;
          font-weight: 400;
          font-family: "PingFang SC";
        }
      }
      .btn {
        display: flex;
        align-items: flex-end;
        .btnsell {
          width: 136px;
          height: 72px;
          border-radius: 16px;
          opacity: 1;
          border: 2px solid rgba(214, 255, 227, 1);
          color: rgba(214, 255, 227, 1);
          font-size: 28px;
          font-weight: 700;
          font-family: "PingFang SC";
          text-align: center;
          line-height: 72px;
          margin-right: 24px;
        }
        .btnbuy {
          width: 136px;
          height: 72px;
          border-radius: 16px;
          opacity: 1;
          background: rgba(214, 255, 227, 1);
          color: rgba(32, 40, 35, 1);
          font-size: 28px;
          font-weight: 700;
          font-family: "PingFang SC";
          text-align: center;
          line-height: 72px;
        }
      }
    }
    .recordlog {
      margin-top: 16px;
      color: rgba(214, 255, 227, 1);
      font-size: 28px;
      font-weight: 400;
      font-family: "PingFang SC";
      text-align: right;
    }
  }
  .orderBy {
    margin-top: 32px;
    .ocontent {
      display: flex;
      align-items: center;
      .ordertxt {
        color: rgba(182, 217, 195, 1);
        font-size: 28px;
        font-weight: 400;
        font-family: "PingFang SC";
      }
      .ordericon {
        width: 24px;
        height: 24px;
      }
    }
  }
  .buyandsell {
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .order {
      opacity: 1;
      color: rgba(39, 204, 127, 1);
      font-size: 28px;
      font-weight: 400;
      font-family: "PingFang SC";
      text-align: center;
    }
  }
  .list {
    margin-top: 10px;
    .cell {
      width: 686px;
      height: 276px;
      border-radius: 24px;
      opacity: 1;
      background: rgba(32, 40, 35, 1);
      padding: 24px;
      margin-bottom: 30px;
      .title {
        color: rgba(182, 217, 195, 1);
        font-weight: 400;
        font-family: "PingFang SC";
        font-size: 24px;
      }
      .middle {
        margin-top: 16px;
        display: flex;
        justify-content: start;
        align-items: center;
        .price {
          .num {
            color: rgba(214, 255, 227, 1);
            font-size: 44px;
            font-weight: 500;
            font-family: "DIN";
            text-align: center;
            line-height: 60px;
          }
          .txt {
            color: rgba(139, 166, 149, 1);
            font-size: 24px;
            font-weight: 400;
            font-family: "PingFang SC";
            text-align: left;
            line-height: 36px;
          }
        }
      }
      .end {
        margin-top: 24px;
        display: flex;
        justify-content: space-between;
        .way {
          color: rgba(139, 166, 149, 1);
          font-size: 28px;
          font-weight: 400;
          font-family: "PingFang SC";
          text-align: left;
          line-height: 40px;
          display: flex;
          align-items: center;
          .method {
            display: flex;
            align-items: center;
            .payway {
              display: flex;
              align-items: center;
              .payicon {
                margin-right: 10px;
                width: 32px;
                height: 32px;
              }
            }
          }
          .payicon {
            width: 32px;
            height: 32px;
          }
        }
        .buy {
          color: rgba(39, 204, 127, 1);
          font-size: 28px;
          font-weight: 700;
          font-family: "PingFang SC";
          text-align: center;
          line-height: 40px;
        }
      }
    }
  }
}
</style>