

export const path = {
    register: '/index/user/register',
    getUserInfo: '/index/user/getUserInfo',
    getBanner: '/index/user/getBanner',
    homeStatistics: '/index/user/homeStatistics',
    getNoticeList: '/index/user/getNoticeList',
    getNoticeDetail: '/index/user/getNoticeDetail',
    huanLogList: '/index/Milieu/huanLogList',
    myStatistics: '/index/My/myStatistics',
    activeLog: '/index/my/activeLog',
    getWalletInfo: '/index/Wallet/getWalletInfo',
    transferLog: '/index/Wallet/transferLog',
    exchangeLog: '/index/Wallet/exchangeLog',
    fanDuiLog: '/index/Milieu/fanDuiLog',
    teamInfo: '/index/User/teamInfo',
    inviteRecord: 'index/User/inviteRecord',
    getGuideList: 'index/content/getGuideList',
    getGuideDetail: '/index/content/getGuideDetail',
    devoteRank: '/index/my/devoteRank',
    devoteLog: '/index/my/devoteLog',
    fanLog: '/index/my/fanLog',
    daiLog: '/index/my/daiLog',
    problemList: '/index/Content/problemList',
    addProblem: '/index/Content/addProblem',
    uploadImg: '/index/content/uploadImg',
    getCode: '/index/My/getCode',
    userComplete: '/index/my/userComplete',
    getPaylist: '/index/my/getPaylist',
    addPayWay: '/index/my/addPayWay',
    editPayWay: '/index/my/editPayWay',
    authentication: '/index/my/authentication',
    chargeLog: 'index/Wallet/chargeLog',
    getWithdrawConfig: '/index/Wallet/getWithdrawConfig',
    checkWithdraw: '/index/Wallet/checkWithdraw',
    withdrawLog: '/index/Wallet/withdrawLog',
    getResourceList: '/index/Milieu/getResourceList',
    getOptionDesc: '/index/user/getOptionDesc',  //获取描述配置
    getOption: '/index/user/getOption',  //获取配置
    bindParent: '/index/user/bindParent',
    getHuanList: '/index/Milieu/getHuanList',
    resourceLogList: '/index/Milieu/resourceLogList',
    fanDui: 'index/Milieu/fanDui',
    duiConfig: 'index/Wallet/duiConfig',
    canHuan: '/index/Milieu/canHuan',  // 参与环保记录
    canResource: '/index/Milieu/canResource',  //参与资源转换器
    canDui: '/index/Milieu/canDui', //参与兑换
    questionList: '/index/content/questionList', //获取问题答案
    submitAnswer: '/index/content/submitAnswer', //参与答题
    tradeStatistics: '/index/trade/tradeStatistics',
    buyList: '/index/trade/buyList',
    sellList: '/index/trade/sellList',
    hangBuy: 'index/trade/hangBuy',
    hangSell: 'index/trade/hangSell',
    orderDetail: '/index/trade/orderDetail',
    buyOrder: '/index/trade/buyOrder',
    sellOrder: '/index/trade/sellOrder',
    myRecordLog: 'index/trade/myRecordLog',
    paymentSubmit: '/index/trade/paymentSubmit',
    appealPayment: '/index/trade/appealPayment',
    confirmRelease: '/index/trade/confirmRelease',
    viewSellPayWay: '/index/trade/viewSellPayWay',
    viewPayment: '/index/trade/viewPayment',
    myHangLog: '/index/trade/myHangLog',
    cancleOrder: 'index/trade/cancleOrder',
    viewAppeal: '/index/trade/viewAppeal',
    viewOrderDetail: '/index/trade/viewOrderDetail',
    waitOrder: '/index/trade/waitOrder',
    getTradeConfig: '/index/trade_usdt/getTradeConfig',
    applyTradeUsdt: '/index/trade_usdt/applyTradeUsdt',
    exitTradeUsdt: '/index/trade_usdt/exitTradeUsdt',
    withdrawApply: '/index/Wallet/withdrawApply',
    voteInfo: '/index/Vote/voteInfo',
    submitVote: '/index/Vote/submitVote',
}