import {
    get,
    post
} from './http'

import {
    path
} from './path'

// 登录页面api
// export let login = p => post('/aaa', p); //登录
// export let getCode = p => get('/bbb', p); //登录

export let register = p => get(path.register, p)
export let getUserInfo = p => get(path.getUserInfo, p)
export let getBanner = p => get(path.getBanner, p)
export let homeStatistics = p => post(path.homeStatistics, p)
export let getNoticeList = p => post(path.getNoticeList, p)
export let getNoticeDetail = p => post(path.getNoticeDetail, p)
export let huanLogList = p => post(path.huanLogList, p)
export let myStatistics = p => post(path.myStatistics, p)
export let activeLog = p => get(path.activeLog, p)
export let getWalletInfo = p => post(path.getWalletInfo, p)
export let transferLog = p => get(path.transferLog, p)
export let exchangeLog = p => get(path.exchangeLog, p)
export let fanDuiLog = p => get(path.fanDuiLog, p)
export let teamInfo = p => post(path.teamInfo, p)
export let inviteRecord = p => post(path.inviteRecord, p)
export let getGuideList = p => post(path.getGuideList, p)
export let getGuideDetail = p => post(path.getGuideDetail, p)
export let devoteRank = p => post(path.devoteRank, p)
export let devoteLog = p => post(path.devoteLog, p)
export let fanLog = p => post(path.fanLog, p)
export let daiLog = p => post(path.daiLog, p)
export let problemList = p => get(path.problemList, p)
export let addProblem = p => post(path.addProblem, p)
export let uploadImg = p => post(path.uploadImg, p)
export let getCode = p => post(path.getCode, p)
export let userComplete = p => post(path.userComplete, p)
export let getPaylist = p => post(path.getPaylist, p)
export let addPayWay = p => post(path.addPayWay, p)
export let editPayWay = p => post(path.editPayWay, p)
export let authentication = p => post(path.authentication, p)
export let chargeLog = p => get(path.chargeLog, p)
export let getWithdrawConfig = p => post(path.getWithdrawConfig, p)
export let withdrawLog = p => get(path.withdrawLog, p)
export let getResourceList = p => get(path.getResourceList, p)
export let getOptionDesc = p => post(path.getOptionDesc, p)
export let getOption = p => post(path.getOption, p)
export let bindParent = p => get(path.bindParent, p)
export let getHuanList = p => post(path.getHuanList, p)
export let resourceLogList = p => post(path.resourceLogList, p)
export let duiConfig = p => post(path.duiConfig, p)
export let fanDui = p => post(path.fanDui, p)
export let canHuan = p => post(path.canHuan, p)
export let canResource = p => post(path.canResource, p)
export let canDui = p => post(path.canDui, p)
export let questionList = p => post(path.questionList, p)
export let submitAnswer = p => post(path.submitAnswer, p)
export let tradeStatistics = p => post(path.tradeStatistics, p)
export let buyList = p => post(path.buyList, p)
export let sellList = p => post(path.sellList, p)
export let hangBuy = p => post(path.hangBuy, p)
export let hangSell = p => post(path.hangSell, p)
export let orderDetail = p => post(path.orderDetail, p)
export let buyOrder = p => post(path.buyOrder, p)
export let sellOrder = p => post(path.sellOrder, p)
export let myRecordLog = p => post(path.myRecordLog, p)
export let paymentSubmit = p => post(path.paymentSubmit, p)
export let appealPayment = p => post(path.appealPayment, p)
export let confirmRelease = p => post(path.confirmRelease, p)
export let viewSellPayWay = p => post(path.viewSellPayWay, p)
export let viewPayment = p => post(path.viewPayment, p)
export let myHangLog = p => post(path.myHangLog, p)
export let cancleOrder = p => post(path.cancleOrder, p)
export let viewAppeal = p => post(path.viewAppeal, p)
export let viewOrderDetail = p => post(path.viewOrderDetail, p)
export let waitOrder = p => post(path.waitOrder, p)

export let getTradeConfig = p => post(path.getTradeConfig, p)
export let applyTradeUsdt = p => post(path.applyTradeUsdt, p)
export let exitTradeUsdt = p => post(path.exitTradeUsdt, p)

export let checkWithdraw = p => post(path.checkWithdraw, p)

export let withdrawApply = p => post(path.withdrawApply, p)

export let voteInfo = p => get(path.voteInfo, p)
export let submitVote = p => post(path.submitVote, p)