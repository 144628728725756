<template>
    <div>
        <van-action-sheet v-model="showinvite" title="绑定邀请" class="modalclass">
            <div class="inviteclass">
                <div class="inputcontent">
                    <van-field class="inputClass" :center="true" v-model="inviteValue" placeholder="请填写邀请人地址"/>
                </div>
                <div class="btnContent" @click="clickBind">
                    <div class="btn">
                        绑定
                    </div>
                </div>
            </div>
        </van-action-sheet>
    </div>
</template>

<script>
    import metamaskConfig from '../../../components/connection.js'
    import { ethers } from '../../../components/ethers-5.1.esm.min.js'
    import {bindParent} from '@/request/api'
    import {Toast} from 'vant';
    let  defaultAccount, networkVersion;
    export default {
        name: 'CodeInvite',
        data() {
            return {
                showinvite: false,
                inviteValue: '',
                address:sessionStorage.getItem("address")
            };
        },

        activated() {

        },

        methods: {
            async checkLogin(){
                try {
                    if (metamaskConfig.isMetamaskConnected) {
                        ethereum.autoRefreshOnNetworkChange = false;
                        defaultAccount = await metamaskConfig.connectToAccount()
                        console.log('address connected:', defaultAccount)
                        if(defaultAccount[0]){
                            this.address = defaultAccount[0];
                        }
                    }
                } catch (e) {
                    console.log('1111')
                }
            },
            preview() {
                this.showinvite = true
                this.inviteValue = ''
                if(!this.address){
                    this.checkLogin();
                }
            },
            clickBind() {
                if (this.inviteValue.length === 0) {
                    Toast('请输入邀请人地址')
                    return
                }
                this.sendbindParent()
            },
            async sendbindParent() {
                const params = {
                    address: this.address,
                    p_address: this.inviteValue
                };
                bindParent(params).then(res => {
                    if (res.code==200) {
                        Toast('操作成功');
                        this.showinvite = false
                    }else{
                        Toast('绑定失败');
                        setTimeout(function () {
                            location.reload();
                        },3000)
                    }

                })
               /* try {
                    let message = "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef";

                    let messageBytes = ethers.utils.arrayify(message);

                    let signature = await metamaskConfig.signer.signMessage(messageBytes)
                    if(signature){

                    }

                } catch (e) {
                    Toast('操作失败');
                    setTimeout(function () {
                        location.reload();
                    },2500)
                }*/


            }
        },
    };
</script>

<style lang="scss" scoped>
    .modalclass {
        background: #202823;
        border-radius: 12px 12px 0px 0px;
        color: #F5FFF9;
        .inviteclass {
            padding: 16px 16px 100px;
            .inputcontent {
                display: flex;
                justify-content: center;
                .inputClass {
                    width: 600px;
                    height: 96px;
                    background: #29332D;
                    border-radius: 16px;
                    ::v-deep .van-field__control {
                        color: white;
                    }
                }
            }
        }
        .btnContent {
            display: flex;
            justify-content: center;
            margin-top: 80px;
            .btn {
                width: 320px;
                height: 88px;
                background: #27CC7F;
                border-radius: 16px;
                font-weight: 600;
                font-size: 36px;
                line-height: 88px;
                text-align: center;
                color: #202823;
            }
        }
        .payClass {
            padding: 16px 16px 100px;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            .numcard {
                display: flex;
                justify-content: space-around;
                align-items: center;
                width: 686px;
                height: 168px;
                background: #29332D;
                border: 1px solid rgba(39, 204, 127, 0.2);
                border-radius: 16px;
                .txttip {
                    text-align: center;
                    .tipnum {
                        font-family: 'DIN';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 44px;
                        color: #D6FFE3;
                    }
                    .tip {
                        font-family: 'PingFang SC';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 24px;
                        color: #B6D9C3;
                    }
                }
            }
            .surplus {
                margin-top: 10px;
                width: 100%;
                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 400;
                font-size: 28px;
                text-align: center;
                color: #8BA695;
            }
            .notice {
                width: 100%;
                padding: 0 36px;
                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                line-height: 50px;
                color: #B6D9C3;
            }
        }

    }
</style>