import Vue from 'vue'

const state = {
    userInfo: {
        activa: 0,
        address: "0x418c47eb99eb9b37b1d599c29d81633a5fdde517",
        fee: 0,
        freeze: 0,
        has_paypwd: '123456',
        id: 9,
        is_alipay: 0,
        is_auth: 0,
        is_card: 0,
        is_wx: 0,
        level_id: 0,
        mobile: "",
        name: "",
        p_address: "0x0000000000000000000000000000000000000000",
        partner_level_id: 0,
        pid: 1,
        timestamp: "2022-08-20 13:47:54"
    }
}

const mutations = {
    setUserinfo(state, info) {
        state.userInfo = info
        Vue.ls.set('user', info);
    }
}

const actions = {

}

const getters = {

}

export default {
    state,
    mutations,
    actions,
    getters
}
