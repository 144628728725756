<template>
  <div>
    <van-action-sheet :close-on-click-overlay="false" v-model="showreg" title="注册引导" class="modalclass">
        <div class="regclass">
            <div class="regtxt" v-html="regdesc">
            </div>
            <div class="regGuide">
                更多介绍请前往<a href="">《操作查看指南》</a>
            </div>
        </div>
    </van-action-sheet>
  </div>
</template>

<script>
import {getOptionDesc} from '@/request/api'
export default {
    data (){
        return {
            showreg:false,
            regdesc:''
        }
    },
    methods: {
        preview(){
            this.showreg = true
            this.getResigerDesc()
        },
        getResigerDesc () {
            getOptionDesc({name: 'guide_desc'}).then(res=> {
                this.regdesc = res.data.value_zh
            })
        }
    }
}
</script>

<style scoped lang="scss">
.modalclass {
    // background: #202823;
    border-radius: 12px 12px 0px 0px;
    color: #B6D9C3;
    .regclass {
        padding: 16px 16px 100px;
        .regtxt {
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 50px;
            color: #B6D9C3;
        }
        .regGuide {
            margin-top: 24px ;
            text-align: center;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            color: #8BA695;
        }
         a {
                color:  #27CC7F;;
            }
     }
}
</style>