<template>
  <div class="index-section">
    <div class="topbg">
      <div class="topclass">
        <img class="logoimg" :src="require('@/assets/images/logo.png')" />
        <img class="titleimg" v-if="address" :src="getlevelImg" />
        <img
          class="levelimg"
          v-if="address && userInfo.partner_level_id > 0"
          :src="getLevelIcon"
        />
        <div class="rightbtn">
          <template v-if="!address">
            <div class="txt" @click="connectUser()">链接钱包</div>
          </template>
          <template v-else>
            <div class="txt">{{ short_address }}</div>
          </template>
          <img
            class="arrowimg"
            :src="require('@/assets/images/index/arrow.png')"
          />
        </div>
      </div>
      <div class="banner">
        <van-swipe class="myswipe" :autoplay="3000" indicator-color="white">
          <van-swipe-item v-for="item in bannerList" :key="item.id">
            <img class="bannerimg" :src="item.images_url" />
          </van-swipe-item>
        </van-swipe>
        <!-- <img class="bannerimg" :src="require('@/assets/images/index/ept_bg.png')" /> -->
      </div>
    </div>
    <div class="msgbox" @click="clickAnnounce">
      <div class="messagetxt">
        <img
          class="messageicon"
          :src="require('@/assets/images/index/message.png')"
        />
        {{ message }}
      </div>
      <img class="msgarrow" :src="require('@/assets/images/index/arrow.png')" />
    </div>
    <div class="content">
      <div class="topnum">
 <!--       <div class="accumulated">
                    <div class="num">{{statistics.todayDestory||0 }}</div>
                    <div class="bottomtxt">今日销毁(ATT)</div>
                </div>-->
        <div class="accumulated">
          <div class="num">
            {{ parseFloat(statistics.todayProfit).toFixed(4) }}
          </div>
          <div class="bottomtxt">今日获得(ATT)</div>
        </div>
          <!--<div class="accumulated">-->
                    <!--<div class="num">{{statistics.totalDestory||0   }}</div>-->
                    <!--<div class="bottomtxt">总共销毁(ATT)</div>-->
                <!--</div>-->
      </div>
      <div class="centerimg">
        <div>
          <div class="fruit">
            <img
              @click="clickAction(4)"
              class="position1 toolimg"
              :src="require('@/assets/images/index/guoshi.png')"
            />
            <img
              @click="clickAction(4)"
              class="position2 toolimg"
              :src="require('@/assets/images/index/guoshi.png')"
            />
            <img
              @click="clickAction(4)"
              class="position3 toolimg"
              :src="require('@/assets/images/index/guoshi.png')"
            />
            <img
              @click="clickAction(4)"
              class="position4 toolimg"
              :src="require('@/assets/images/index/guoshi.png')"
            />
            <img
              @click="clickAction(4)"
              class="position5 toolimg"
              :src="require('@/assets/images/index/guoshi.png')"
            />
          </div>
          <div class="action">
            <img
              @click="clickAction(1)"
              class="toolimg"
              :src="require('@/assets/images/index/tieqiao.png')"
            />
            <img
              @click="clickAction(2)"
              class="toolimg"
              :src="require('@/assets/images/index/jiaoshui.png')"
            />
            <img
              @click="clickAction(3)"
              class="toolimg"
              :src="require('@/assets/images/index/lajd.png')"
            />
          </div>
        </div>
        <div class="bottom">
          <div class="imgtxt" @click="clickAction(0)">
            <div>
              <img
                class="brand"
                :src="require('@/assets/images/index/huanbao.png')"
              />
              <div class="titletxt">环保行为</div>
            </div>
          </div>
          <div class="imgtxt" @click="gotoTransfor">
            <div>
              <img
                class="brand"
                :src="require('@/assets/images/index/tramask.png')"
              />
              <div class="titletxt">资源转换</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottomcard">
      <div class="card recordbg" @click="clickRecord">
        <div class="cardtitle">环保行为记录</div>
        <div class="cardsubtitle">共{{ statistics.count }}条记录</div>
        <img
          class="caoimg"
          :src="require('@/assets/images/index/shuidi_icon.png')"
        />
      </div>
      <div class="card joinbg" @click="cliclRecord">
        <div class="cardtitle">资源转换记录</div>
        <div class="cardsubtitle">共{{ statistics.rcount || 0 }}条记录</div>
        <img class="caoimg" :src="require('@/assets/images/index/cao.png')" />
      </div>
    </div>
    <language-sheet></language-sheet>
    <reg-sheet ref="regsheet"></reg-sheet>
    <invite ref="invite"></invite>
    <action ref="action"></action>
  </div>
</template>

<script>
import metamaskConfig from "../../components/connection.js";
import { ethers } from "../../components/ethers-5.1.esm.min.js";
import Invite from "./modal/invite.vue";
import LanguageSheet from "./modal/languageSheet.vue";
import RegSheet from "./modal/regSheet.vue";
import {
  getBanner,
  homeStatistics,
  getNoticeList,
  register,
  getUserInfo,
  getOption,
} from "@/request/api";
import Action from "./modal/action.vue";
import { Toast } from "vant";
let defaultAccount, networkVersion;

export default {
  components: {
    LanguageSheet,
    RegSheet,
    Invite,
    Action,
    // Header
  },
  data() {
    return {
      lang: 1,
      message: "",
      bannerList: [],
      statistics: {
        count: 0,
        rcount: 0,
        expireNum: 0,
        todayProfit: 0,
        totalProfit: 0,
          todayDestory:0,
          totalDestory:0,
      },
      address: "",
      short_address: "",
      userInfo: {},
      eco_switch: 0, //环保行为开关 0关闭 ,1开启
    };
  },
  computed: {
    getlevelImg() {
      //0环保新秀，1环保一星 2环保二星 3环保三星 4 环保四星
      let img = "";
      let levelid = this.userInfo.level_id;
      switch (levelid) {
        case 0:
          img = require("@/assets/images/index/title_xinxiu.png");
          break;
        case 1:
          img = require("@/assets/images/index/title_weishi.png");
          break;
        case 2:
          img = require("@/assets/images/index/title_daren.png");
          break;
        case 3:
          img = require("@/assets/images/index/title_dashi.png");
          break;
        case 4:
          img = require("@/assets/images/index/title_hbyx.png");
          break;
      }
      return img;
    },
    getLevelIcon() {
      let img = "";
      let levelid = this.userInfo.partner_level_id;
      switch (levelid) {
        case 1:
          img = require("@/assets/images/index/title_cyh.png");
          break;
        case 2:
          img = require("@/assets/images/index/title_cjh.png");
          break;
        case 3:
          img = require("@/assets/images/index/title_lmh.png");
          break;
        case 4:
            img = require("@/assets/images/index/title_shequ.png");
            break;
      }
      return img;
    },
  },
  activated() {
    this.checkLogin();
    getBanner({ type: "1" }).then((res) => {
      this.bannerList = res.data;
    });
    getOption({ name: "eco_switch" }).then((res) => {
      this.eco_switch = res.data;
    });
    getNoticeList({ cate: this.lang, page: 1, limit: 1 }).then((res) => {
      if (res.data["list"].length > 0) {
        this.message = res.data["list"][0]["title"];
      }
    });
  },
  methods: {
    async checkLogin() {
      try {
        if (metamaskConfig.isMetamaskConnected) {
          ethereum.autoRefreshOnNetworkChange = false;
          networkVersion = await metamaskConfig.getNetworkId();
          if (networkVersion == 1) {
            //以太坊主网
            console.log("ETH");
            Toast("请使用币安智能链链接");
            return false;
          } else if (networkVersion == 42) {
            //以太坊测试网  K
            console.log("ETH");
            Toast("请使用币安智能链链接");
            return false;
          } else if (networkVersion == 128) {
            //HECO主网
            console.log("HT");
            Toast("请使用币安智能链链接");
            return false;
          } else if (networkVersion == 256) {
            //HECO测试网
            console.log("HT");
            Toast("请使用币安智能链链接");
            return false;
          } else if (networkVersion == 56) {
            //币安主网
            console.log("BNB");
          } else {
            console.log("其他");
            Toast("请使用币安智能链链接");
            return false;
          }
          defaultAccount = await metamaskConfig.connectToAccount();
          console.log("address connected:", defaultAccount);
          if (defaultAccount[0]) {
            this.address = defaultAccount[0];
            this.short_address =
              this.address.substr(0, 3) +
              "..." +
              this.address.substr(this.address.length - 3);
            sessionStorage.removeItem("address");
            sessionStorage.removeItem("user");
            sessionStorage.removeItem("level_id");
            sessionStorage.setItem("address", this.address);
            this.regUser();
            if(this.address &&sessionStorage.getItem(this.address)){
                homeStatistics({ address: this.address }).then((res) => {
                    this.statistics = res.data;
                });
            }
          }
        } else {
          Toast("请使用币安智能链链接");
          console.log("Connect to available ethereum network!");
        }
      } catch (e) {
        this.$refs.regsheet.preview();
        console.log("1111");
      }
    },

    regUser() {
      let _this = this;
      register({ address: this.address }).then((res) => {
          if(_this.address &&sessionStorage.getItem(_this.address)){
              _this.getUserInfo();
          }
      });
    },
    getUserInfo() {
      getUserInfo({ address: this.address }).then((res) => {
        this.userInfo = res.data;
        sessionStorage.setItem("user", JSON.stringify(res.data));
        sessionStorage.setItem("level_id", res.data.level_id);
        console.log(sessionStorage.getItem("user"));
        if (res.data.pid == 0) {
          this.$refs.invite.preview();
        }
      });
    },
    async connectUser() {
      let banner = 0;
      let getAccountAddress = await metamaskConfig.getAccount();
      if (getAccountAddress.length < 1) {
        getAccountAddress = await metamaskConfig.connectToAccount();
        banner = await metamaskConfig.getBalance();
        location.reload();
      } else {
        banner = await metamaskConfig.getBalance();
        location.reload();
      }
    },
    gotoTransfor() {
      if (!this.address) {
        Toast("请使用币安智能链链接");
        return false;
      }
      this.$router.push({
        path: "/home/index/transfor",
      });
    },
    clickAnnounce() {
      this.$router.push({
        path: "/home/index/announce",
      });
    },
    cliclRecord() {
      this.$router.push({
        path: "/home/index/exchange",
      });
    },
    clickRecord() {
      this.$router.push({
        path: "/home/index/environmentrecord",
      });
    },
    clickAction(title) {
      console.log(title);
      if (this.eco_switch == 0) {
        Toast("即将开放");
        return false;
      }
      if (!this.address) {
        Toast("请使用币安智能链链接");
        return false;
      }
      this.$refs.action.preview(title);
    },
  },
};
</script>

<style scoped lang="scss">
.index-section {
  // background: #144C32;
  background: rgba(13, 25, 18, 1);
  min-height: 100vh;
  padding-bottom: 10px;
  .topbg {
    background: url("../../assets/images/index/topbg.png");
    background-size: 100% 100%;
  }
  // padding: 40px 32px;
  .topclass {
    // background: #144C32;
    display: flex;
    justify-content: space-between;
    padding: 40px 32px;
    .logoimg {
      /*width: 176px;*/
      height: 72px;
    }
    .titleimg {
      /*width: 208px;*/
      height: 72px;
    }
    .levelimg {
      /*width: 72px;*/
      height: 72px;
    }
    .arrowimg {
      width: 40px;
      height: 40px;
    }
    .rightbtn {
      font-family: "PingFang SC";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #ccfb24;
      display: flex;
      align-items: center;
    }
  }
  .banner {
    padding: 0px 32px;
    display: flex;
    justify-content: center;
    .myswipe {
      .bannerimg {
        width: 686px;
        height: 280px;
      }
    }
  }
  .msgbox {
    max-width: 686px;
    margin-left: 32px;
    padding: 10px 0px;
    background: #202823;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .messagetxt {
      font-family: "PingFang SC";
      font-style: normal;
      font-weight: 400;
      font-size: 28px;
      color: #b6d9c3;
      display: flex;
      align-items: center;
      .messageicon {
        width: 48px;
        height: 48px;
        margin-left: 10px;
        margin-right: 10px;
      }
    }
    .msgarrow {
      width: 30px;
      height: 30px;
    }
  }
  .content {
    margin-top: 24px;
    padding: 0px 32px;
    .topnum {
      display: flex;
      justify-content: space-around;
      border-radius: 8px;
      opacity: 1;
      background: linear-gradient(
        180deg,
        rgba(25, 67, 21, 1) 0%,
        rgba(16, 43, 29, 1) 20%,
        rgba(16, 43, 29, 1) 100%
      );
      .accumulated {
        text-align: center;
        padding: 32px 0px;
        .num {
          color: rgba(214, 255, 227, 1);
          font-size: 44px;
          font-weight: 500;
          font-family: "DIN";
          line-height: 60px;
        }
        .bottomtxt {
          color: rgba(182, 217, 195, 1);
          font-size: 24px;
          font-weight: 400;
          font-family: "PingFang SC";
        }
      }
    }

    .centerimg {
      // display: flex;
      // justify-content: center;
      position: relative;
      width: 686px;
      height: 600px;
      background: url("../../assets/images/index/treeimg.png");
      background-size: 100% 100%;
      border-radius: 8px;
      margin-top: 24px;
      .toolimg {
        width: 64px;
        height: 64px;
        margin-bottom: 32px;
      }
      .fruit {
        .position1 {
          position: relative;
          left: 20px;
          top: 300px;
        }
        .position2 {
          position: relative;
          left: 40px;
          top: 220px;
        }
        .position3 {
          position: relative;
          left: 30px;
          top: 300px;
        }
        .position4 {
          position: relative;
          left: 425px;
          top: 320px;
        }
        .position5 {
          position: relative;
          left: 275px;
          top: 320px;
        }
      }
      .action {
        position: absolute;
        display: flex;
        flex-direction: column;
        left: 580px;
        top: 50px;
      }
      .bottom {
        display: flex;
        justify-content: space-around;
        width: 100%;
        position: absolute;
        bottom: -8%;
        .imgtxt {
          color: rgba(105, 52, 11, 1);
          font-size: 32px;
          font-weight: 700;
          font-family: "PingFang SC";
          text-align: left;
          .brand {
            width: 248px;
            height: 136px;
          }
          .titletxt {
            position: relative;
            top: -105px;
            left: 60px;
          }
        }
      }
    }
  }
  .bottomcard {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    .joinbg {
      background: linear-gradient(
        104.59deg,
        #2c4c37 0.76%,
        #2a4835 7.88%,
        #1d3024 53.02%,
        #1a291f 98.49%
      );
    }
    .recordbg {
      background: linear-gradient(
        104.59deg,
        #364c2c 0.76%,
        #374c2c 0.77%,
        #24301d 53.02%,
        #1f291a 98.49%
      );
    }
    .card {
      width: 316px;
      height: 168px;

      border-radius: 8px;
      padding: 30px 15px;
      .cardtitle {
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 600;
        font-size: 26px;
        line-height: 20px;
        color: #d6ffd7;
      }
      .cardsubtitle {
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 18px;
        color: #8ba695;
        margin-top: 20px;
      }
      .caoimg {
        width: 80px;
        height: 80px;
        float: right;
      }
    }
  }
}

.btn {
  margin: 0 auto;
  margin-top: 50px;
  width: 500px;
}
</style>