<template>
    <div class="home-section">
        <div class="tab-page cant-tab-page">
            <!-- <transition name="page-switch-fade"> -->
            <keep-alive>
                <router-view></router-view>
            </keep-alive>
            <!-- </transition> -->
        </div>
        <van-tabbar 
            class="tab-bar" 
            v-model="$store.state.home.active"
            active-color="#27CC7F"
            inactive-color="#8BA695"
            @change="tabChange"
        >
            <van-tabbar-item name="/home/index" class="tabitem">
                <span>首页</span>
                <template #icon="props">
                    <img v-if="props.active" :src="require('@/assets/images/home_select.png')" />
                    <img v-else :src="require('@/assets/images/home.png')" />
                </template>
            </van-tabbar-item>
            <van-tabbar-item name="/home/task" class="tabitem">
                <span>市场</span>
                <template #icon="props">
                    <img v-if="props.active" :src="require('@/assets/images/transaction_select.png')" />
                    <img v-else :src="require('@/assets/images/transaction.png')" />
                </template>
            </van-tabbar-item>
            <van-tabbar-item name="/home/mine/tradeCoin" class="tabitem">
                <span>交易</span>
                <template #icon="props">
                    <img v-if="props.active" :src="require('@/assets/images/cake_select.png')" />
                    <img v-else :src="require('@/assets/images/cake.png')" />
                </template>
            </van-tabbar-item>
            <van-tabbar-item name="/home/scene" class="tabitem">
                <span>环保</span>
                <template #icon="props">
                    <img v-if="props.active" :src="require('@/assets/images/classfity_select.png')" />
                    <img v-else :src="require('@/assets/images/classfity.png')" />
                </template>
            </van-tabbar-item>
            <van-tabbar-item name="/home/mine" class="tabitem">
                <span>我的</span>
                <template #icon="props">
                    <img v-if="props.active" :src="require('@/assets/images/my_select.png')" />
                    <img v-else :src="require('@/assets/images/my.png')" />
                </template>
            </van-tabbar-item>
        </van-tabbar>
    </div>
</template>

<script>
    import { Toast } from "vant";
export default {
    name:'',
    components: {},
    data() {
        return {
            address:sessionStorage.getItem("address")
        }
    },
    created () {
        const router = this.$router
        router.push({
            path:'/home/index'
        })
        console.log('111111111111111111'+this.$store.state.home.active)
    },
    activated(){
        if(!this.address){
            this.address = sessionStorage.getItem("address");
        }
    },
    methods: {
        tabChange(active){

            const router = this.$router
            // this.$store.commit("changeTabActive",active)
           /* if(active=='/home/scene'){
                if(!this.address){
                    Toast('请链接钱包');
                    setTimeout(function () {
                        location.reload();
                    },3000)
                }else{
                    location.href=contractConfig.gameUrl+'/#/?address='+this.address
                }
            }else{*/
                router.push({
                    path:active
                })
            // }

        }
    }
}
</script>
<style scoped lang="scss">
    .home-section{
        height: 100%;
        background: #102B1D;;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .tab-page{
            &.cant-tab-page{
                height: calc(100% - 50px);
                overflow: scroll;
            }
            
        }
        .tab-bar{
            position: relative;
            background: #202823;
            .tabitem {
                background: #202823;
            }
        }
    }
</style>