<template>
  <div>
     <van-action-sheet v-model="showlanguage" title="语言切换" class="modalclass">
            <div class="language">
                <div class="btn_china">
                    简体中文
                </div>
                <div class="btn_english">
                    English
                </div>
            </div>
    </van-action-sheet>
  </div>
</template>

<script>
export default {
    components: {
        // Header
    },
    data() {
        return {
            showlanguage: false
        }
    },
}
</script>

<style scoped lang="scss">
.modalclass {
    background: #202823;
    border-radius: 12px 12px 0px 0px;
    color: #F5FFF9;
    .language {
        padding: 16px 16px 100px;
        display: flex;
        justify-content: space-around;
        .btn_china {
            width: 184px;
            height: 72px;
            background: #27CC7F;
            border-radius: 16px;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 600;
            font-size: 32px;
            line-height: 72px;
            text-align: center;
            color: #202823;
        }
        .btn_english {
            width: 184px;
            height: 72px;
            box-sizing: border-box;
            border: 1px solid #27CC7F;
            border-radius: 8px;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 600;
            font-size: 32px;
            line-height: 72px;
            text-align: center;
            color: #27CC7F;
        }
    }   
}

</style>